import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal, NamedLink, InlineTextButton } from '../../components';
import EnquiryForm from './EnquiryForm/EnquiryForm';

import css from './ListingPage.module.css';
import { charLimit } from '../../util/charLimit';
import grapesIcon from './grapes.png';
import { DefaultWineryIcon } from '../../assets';

const SectionAuthorMaybe = props => {
  const {
    title,
    winery,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
  } = props;

  if (!listing.author) {
    return null;
  }

  const handleContactUserClick = () => {
    onContactUser(listing.author);
  };

  return (
    <div id="author" className={css.sectionAuthor}>
      <h2 className={css.aboutProviderTitle}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </h2>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          style={{
            minWidth: '65px',
            maxWidth: '65px',
            height: '65px',
            // background: 'grey',
            // borderRadius: '100%',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <DefaultWineryIcon style={{width: '100%', height: '100%'}} />
        </div>
        <div style={{marginLeft: "16px"}}>
          <h3>Hello! We are {winery?.name}</h3>
          <p>{charLimit(winery?.short_description, 200)}</p>
          <div>
            {winery !== null && (
              <NamedLink name="Winery" params={{slug: winery?.slug, id: winery?._id}}>
                <span>Learn More</span>
              </NamedLink>
            )}

            <InlineTextButton
              rootClassName={css.contact}
              style={{marginLeft: "6px"}}
              onClick={handleContactUserClick}
              enforcePagePreloadFor="SignupPage"
            >
              <FormattedMessage id="UserCard.contactUser" />
            </InlineTextButton>
          </div>
        </div>
      </div>
      {/* <UserCard user={listing.author} currentUser={currentUser} onContactUser={onContactUser} /> */}
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          winery={winery}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
